import { Snowflake } from 'api'
import { BalanceModes, TimecheckModes } from 'balance/types'
import { SnowflakeType } from 'core/types'

export enum Shape {
  Round = 'round',
  Square = 'square',
  RoundedSquare = 'rounded-square',
}

/* Warning, these snowflakes are replicated in src/Domain/Directory/Kind.php and must be kept in sync */
export enum Kinds {
  AbsenceValidator = '403477586100879403',
  Admin = '403477586088296482',
  AdminDirectory = '403477586088296483',
  AdminPlanning = '403477586096685096',
  Client = '362612800803058552',
  Employee = '362612800803058550',
  Extern = '362612800803058554',
  Padawan = '403477586092490789',
  Resident = '362612800803058551',
  Thing = '362612800803058553',
  Integration = '362612800803058555',
}

export const DIRECTORY_ROLE_EMPLOYEE = 'employee'
export const DIRECTORY_ROLE_RESIDENT = 'resident'
export const DIRECTORY_ROLE_CLIENT = 'client'
export const DIRECTORY_ROLE_EXTERN = 'extern'
export const DIRECTORY_ROLE_THING = 'thing'
export const DIRECTORY_ROLE_INTEGRATION = 'integration'

export type DIRECTORY_ROLES =
  | typeof DIRECTORY_ROLE_EMPLOYEE
  | typeof DIRECTORY_ROLE_RESIDENT
  | typeof DIRECTORY_ROLE_CLIENT
  | typeof DIRECTORY_ROLE_EXTERN
  | typeof DIRECTORY_ROLE_THING
  | typeof DIRECTORY_ROLE_INTEGRATION

export type DIRECTORY_ROLES_WITH_DOCUMENTS =
  | typeof DIRECTORY_ROLE_EMPLOYEE
  | typeof DIRECTORY_ROLE_RESIDENT
  | typeof DIRECTORY_ROLE_CLIENT
  | typeof DIRECTORY_ROLE_EXTERN
  | typeof DIRECTORY_ROLE_THING

export type ResourceAccount = {
  username: string
  password: {
    hasPassword: boolean
    forceChange: boolean
    lastChange?: Date
  }
  authenticationLink: {
    lastSentAt?: Date
    lastSentBy?: SnowflakeType
    lastSentTo?: string
  }
  twoFactorAllowed: boolean
}

export type PermissionsType = {
  can_manage: boolean
  can_view_confidential: boolean
  can_view: boolean
  can_manage_roles: boolean
  can_view_planning: boolean
  can_view_own_balances: boolean
  can_view_balances: boolean
  can_view_own_documents: boolean
  can_view_documents: boolean
  can_view_documents_archive: boolean
  can_view_calendar: boolean
  can_take_control: boolean
  can_view_logs: boolean
  can_manage_contracts: boolean
  can_view_variable_values: boolean
  can_view_balance_mode: boolean
  can_view_timecheck_mode: boolean
  can_change_balance_mode: boolean
  can_change_timecheck_mode: boolean
}

type PersonPhonesType = {
  mobile?: string
  private?: string
  work?: string
  work_mobile?: string
  fax?: string
}

type PersonMailsType = {
  private?: string
  work?: string
}

type RefereeType = {
  id: number
  full_name: string
  picture?: string
}

type RefereesType = {
  home_referee: RefereeType[]
  home_coreferee: RefereeType[]
  workshop_referee: RefereeType[]
  doctor_referee: RefereeType[]
  manager_referee: RefereeType[]
}

type RefereesForType = {
  home_referee: RefereeType[]
  home_coreferee: RefereeType[]
  workshop_referee: RefereeType[]
  doctor_referee: RefereeType[]
}

export type SectorType = {
  id: number
  name: string
  head: boolean
}

type ResourcePicture = {
  shape: Shape
  url: string | null
  initial: string
}

export type ResourcePermissions = {
  can_access_profile: boolean
  can_access_account: boolean
  can_access_balances: boolean
  can_access_roles: boolean
  can_access_contracts: boolean
}

export type ResourceSummary = {
  id: SnowflakeType
  label: string
  picture: ResourcePicture
}

export type PersonRecapitulation = {
  picture: string
  fullName: string
  lastName: string
  firstName: string
  job: string
  rate: string
  sectors: SectorType[]
  balanceMode: BalanceModes
  timecheckMode: TimecheckModes
  regrouping: number
  isPaidHourly: boolean
  contractType: string
}

export type PersonType = {
  id: number
  tag_id?: string
  external_id?: string
  is_confidential: boolean
  is_archived: boolean
  is_hidden: boolean
  is_company: boolean
  username?: string
  change_password?: boolean
  snowflake: Snowflake
  general: {
    gender?: string
    civility?: string
    full_name: string
    first_name?: string
    last_name?: string
    picture?: string
    job?: string
    avs?: string
    nationality?: string
    description?: string
    website?: string
    birthdate?: Date
  }
  phones?: PersonPhonesType
  mails?: PersonMailsType
  custom_fields: {
    [x: string]: string
  }
  referees?: RefereesType
  referee_for?: RefereesForType
  contact: {
    address?: string
    city?: string
    zip?: string
    state?: string
    country?: string
    department?: string
  }
  work: {
    sectors: SectorType[]
    role: DIRECTORY_ROLES
    comment?: string
    regrouping?: number
    is_trainee?: boolean
    has_indemnity?: boolean
    is_paid_hourly?: boolean
    contract_type?: string
    rate?: string
    balance_mode?: BalanceModes
    timecheck_mode?: TimecheckModes
    start_date: Date
  }
  permissions?: PermissionsType
}

export type ResourceSearchResult = {
  company: string
  firstName: string
  fullName: string
  id: number
  isCompany: false
  lastName: string
  mainsectorname: string
  picture: string
}
